<template>
  <div id="nav" class="nav bg1">
    <div class="nav-main">
      <router-link to="/">
        <img :src="logo" alt="#" class="logo" />
        <span class="line"></span>
        <span class="logo-text">远程访问理想伴侣</span>
      </router-link>
      <!-- <router-link to="/help" :class="{ active: navActive }">
        帮助中心
      </router-link> -->
      <router-link to="/about">关于我们</router-link>
      <a class="download" @click="goHelp" href="javascript:;">帮助中心</a>
      <router-link to="/download"> 下载 </router-link>
      <router-link to="/business" class="second-nav"> 购买 </router-link>
      <router-link to="/" class="second-nav"> 首页 </router-link>
      <!--    <router-link to="/goumai" class=""> 购买 </router-link>-->
      <!--      <a href="https://jdxb-ionewu.feishu.cn/wiki/wikcnUjZ55Bfvoy5Ngz57wIPhuf" target="_blank">帮助中心</a>-->
      <!--    <router-link to="/zx"> 资讯 </router-link>-->

      <!--    <router-link to="/about"> 关于我们 </router-link>-->

      <!--      <router-link to="/help">-->
      <!--          帮助中心-->
      <!--      </router-link>-->

      <a
        v-if="false"
        href="https://yc.iepose.com/jdxb/"
        class="web-client"
        target="_blank"
        >Web客户端</a
      >
    </div>
  </div>
</template>

<script>
export default {
  name: "Nav",
  data: function () {
    return {
      logo: require("../assets/v4/logo.svg"),
      bg: "",
      navActive: false,
    };
  },
  watch: {
    $route(to, from) {
      let _that = this;
      if (to.path.indexOf("answer") != -1 || to.path.indexOf("help") != -1) {
        _that.navActive = true;
      } else {
        _that.navActive = false;
      }
    },
  },
  props: {},
  methods: {
    goHelp: function () {
      window.location.href = "/helpcenter/";
    },
  },
  mounted() {},
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="less">
.nav {
  position: fixed;
  top: 0;
  height: 70px;
  width: 100%;
  z-index: 9;
  border-bottom: 1px solid #e8eaf3;
  box-sizing: border-box;
  background: rgba(255, 255, 255, 0.75);
  backdrop-filter: blur(15px);

  .nav-main {
    margin: 0 auto;
    width: 1280px;
  }

  a {
    float: right;
    padding-left: 30px;
    padding-right: 30px;
    font-size: 14px;
    color: #1a1a1a;
    text-decoration: none;
    height: 70px;
    line-height: 70px;
    vertical-align: top;
    cursor: pointer;
  }
  a:hover {
    color: #4e4cff;
  }
  a.router-link-exact-active {
    position: relative;

  }
  .active {
    position: relative;
    color: #1a1a1a;
    font-weight: 700;
  }


  a:first-child {
    float: left;
    padding-left: 0px;

    .logo {
      margin-top: 18px;
      width: 156px;
      height: 34px;
    }
    .line {
      display: inline-block;
      vertical-align: top;
      margin-top: 25px;
      margin-left: 16px;
      width: 1px;
      height: 20px;
      background-color: #d3d4db;
    }
    .logo-text {
      display: inline-block;
      margin-left: 12px;
      vertical-align: top;
      font-size: 14px;
      color: #1d2129;
      font-weight: normal;
    }
  }
  a:nth-child(2) {
    padding-right: 0px;
  }
  .second-nav {
    margin-left: 0px;
  }

  .web-client {
    width: 146px;
    height: 36px;
    line-height: 36px;
    text-align: center;
    border-radius: 48px;
    background: linear-gradient(188deg, #655de1 4.55%, #3535c1 93.55%);
    margin-top: 12px;
    color: #fff;
  }
  .web-client:hover {
    cursor: pointer;
    opacity: 0.9;
  }
}
.bg1 {
  /*background-image: linear-gradient(90deg, #f0f5ff 21%, #dae8fb 98%);*/
  // background: linear-gradient(188deg, #655de1 4.55%, #3535c1 93.55%);
  /*background: rgba(195, 195, 210, 0.5);*/
  /*background: #FFFFFF;*/
  background: rgba(255, 255, 255, 0.9);
}
</style>
