<template>
  <div class="home">
    <div class="home-item13" v-if="false">
      <div class="t1">功能强大    超凡易用</div>
      <div class="item item1">
        <div class="i1">智能备份，全天候体验</div>
        <div class="i2">相册一览无遗，助您把握珍藏</div>
        <div class="i3 i3a">无线备份，更便捷舒适。</div>
        <div class="i3">无论是本地，还是外地，备份无视距离。</div>
        <div class="i3">极速传输，即使10000张相册，也是瞬间。</div>
        <div class="i-btn">免费试用</div>
      </div>
      <div class="item item2">
        <div class="i1">远程文件，随心访问</div>
        <div class="i2">让您和文件更近一点，更便捷一些。</div>
        <div class="i3 i3a">近在迟尺、唾手可得、全新体验、超凡易用</div>
        <div class="i3">为工作增添一份舒心，让每次远程多一些惬意</div>
        <div class="i3">多端互联互用，不止于电脑，扩展于TV、投影</div>
        <div class="i-btn">免费试用</div>
      </div>
      <div class="item item1 item3">
        <div class="i1">原生远程桌面，高速高清</div>
        <div class="i2">助您把握异地空间</div>
        <div class="i3 i3a">丝滑原生、即轻且安全</div>
        <div class="i3">手机、PC切换游刃有余，自在无忧</div>
        <div class="i3">超凡连接，高速通道，提升桌面感知</div>
        <div class="i-btn">免费试用</div>
      </div>
      <div class="item item2 item4">
        <div class="i1">内网穿透，超凡易用</div>
        <div class="i2">自定义域名快速穿透</div>
        <div class="i3 i3a">自定义域名访问内网服务，支持身份认证、网络认证、设备</div>
        <div class="i3">认证保障访问安全性。同时能适应各种复杂的路由和防火墙</div>
        <div class="i3">访问到内网服务。采用自主研发的 CSDPI连接技术，稳定可</div>
        <div class="i3">靠的远程访问。</div>
        <div class="i-btn">免费试用</div>
      </div>
      <div class="item item1 item5">
        <div class="i1">异地智能组网</div>
        <div class="i2">快速组网互通互联</div>
        <div class="i3 i3a">即装即用创新组网，不仅有极快的连接速度，还能组建私用</div>
        <div class="i3">专网，基于P2PWAN技术实现跨地域互联，还原远程局域网</div>
        <div class="i3">访问架构，助您访问ERP、OA、CRM、财务软件、营销软</div>
        <div class="i3">件、视频监控、数据中心、SMB共享文件等各种远程服务。</div>
        <div class="i-btn">免费试用</div>
      </div>
    </div>
    <div class="home-item1">
      <div class="home-item1-c">
        <div class="t1">节点小宝-远程访问理想伴侣</div>
        <div class="t2">
          一款专为远程访问设计的网络利器，具备超凡的连接能力。
        </div>
        <div class="t2 t2a">
          因卓越的速度，赢得了众多用户的喜爱与信赖。
        </div>
        <div class="t2 t2a">
          赋能支持远程开发、远程剪辑、远程办公及远程桌面等多种场景。
        </div>
        <div class="t2 t2a">
          远程访问用小宝，是您远程的理想伴侣。
        </div>
        <div class="t3" v-if="false">
          <span class="t3-item">远程办公</span>
          <span class="t3-item t3-item2">远程下载</span>
          <span class="t3-item t3-item2">远程视频</span>
          <span class="t3-item t3-item2">远程Samba</span>
        </div>
        <a
          class="btn-free"
          target="_blank"
          href="https://iepose.com/jdxb/pc/download?device=nas&brand=synology"
          >免费试用</a
        >
        <div class="btn-download">
          <a
            class="btn-d"
            target="_blank"
            href="https://iepose.com/jdxb/pc/download?device=nas&brand=synology"
            ><span class="btn-img btn-img1"></span>Nas</a
          >
          <a
            class="btn-d btn-d2"
            target="_blank"
            href="https://iepose.com/jdxb/pc/download?device=windows"
            ><span class="btn-img btn-img2"></span>Windows</a
          >
          <a
            class="btn-d btn-d3"
            target="_blank"
            href="https://iepose.com/jdxb/pc/download?device=mac"
            ><span class="btn-img btn-img3"></span>macOS</a
          >
          <a
            class="btn-d btn-d4"
            target="_blank"
            href="https://iepose.com/jdxb/pc/download?client=Android"
            ><span class="btn-img btn-img4"></span>Android</a
          >
          <a
            class="btn-d btn-d5"
            target="_blank"
            href="https://iepose.com/jdxb/pc/download?client=ios"
            ><span class="btn-img btn-img5"></span>iOS</a
          >
          <a
            class="btn-d btn-d6"
            target="_blank"
            href="https://iepose.com/jdxb/pc/download?device=docker"
            ><span class="btn-img btn-img6"></span>Docker</a
          >
          <a
            class="btn-d btn-d7"
            target="_blank"
            href="https://iepose.com/jdxb/pc/download?device=openwrt"
            ><span class="btn-img btn-img7"></span>OpenWrt</a
          >
        </div>
      </div>
    </div>
    <div class="home-item2">
      <div class="home-item2-c">
        <div class="t1">节点小宝让网络互联变得简单</div>
        <div class="t2">
          <div class="t2-img"></div>
          <div class="t3">
            <div class="t4">P2P穿透技术传输更极致</div>
            <div class="t5">
              节点小宝即装即用的创新组网，不仅有极快的连接速度，还可以轻松组建可靠安全的专用网，基于P2PWAN点对点互联技术实现跨地域相互连接访问，实现了还原局域网访问架构，快速、稳定的播放视频、管理NAS和路由。访问ERP业务系统、OA系统、CRM系统、文件系统、视频监控、数据中心，挂载共享文件盘等。
            </div>
            <div class="btns">
              <a
                class="btn btn1"
                target="_blank"
                href="https://iepose.com/jdxb/pc/download?device=nas&brand=synology"
                >免费试用</a
              >
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="home-item3">
      <div class="home-item3-c">
        <div class="t1">智能互联互通 安全可靠</div>
        <div class="t2">
          <div class="t3">
            <div class="t4">部署简单</div>
            <div class="t5">支持多种设备终端，无需搭建专线，无需公网IP</div>
          </div>
          <div class="t3 t3a">
            <div class="t4">安全稳定</div>
            <div class="t5">加密通信，保障访问安全，抵御恶意访问</div>
          </div>
          <div class="t3 t3b">
            <div class="t4">服务保障</div>
            <div class="t5">自动选择最佳线路，以保障极速互联</div>
          </div>
          <div class="t3 t3c">
            <div class="t4">随时管理</div>
            <div class="t5">随时通过控制台，制定安全策略，溯源互联</div>
          </div>
        </div>
      </div>
    </div>
    <div class="home-item4">
      <div class="home-item4-c">
        <div class="t1">智能组网 满足不同需求</div>
        <div class="t2">
          <div class="title1">点对点组网</div>
          <div class="title2">两端登录全自动组网</div>
          <div class="t2-img t2-img2"></div>
        </div>
        <div class="t2 t2a">
          <div class="title1">全网映射组网</div>
          <div class="title2">将远程局域网映射到本机</div>
          <div class="t2-img"></div>
        </div>
      </div>
    </div>
    <div class="home-item5">
      <div class="home-item5-c">
        <div class="t1">内网穿透</div>
        <div class="t2">
          <div class="title1">自定义域名快速穿透</div>
          <div class="title2">
            节点小宝内网穿透，可自定义域名轻松通过外网访问内网服务，支持身份认证、网络认证、设备鉴定、加密传输的安全策略，保障访问安全性。节点小宝内网穿透能适应各种复杂的路由和防火墙访问到内网服务。采用自主研发的
            CSDPI（Client Service Data
            Interconnection）通信技术，具备超大并发请求能力。覆盖
            Windows、MacOS、Linux、OpenWRT、NAS、Docker主流系统。
          </div>
          <a
            class="btn1"
            target="_blank"
            href="https://iepose.com/jdxb/pc/download?device=nas&brand=synology"
            >免费试用</a
          >
          <!--          <div class="btn1 btn1b">视频播放</div>-->
          <!--          <div class="btn1 btn1c">开通会员</div>-->
          <div class="t2-img"></div>
        </div>
      </div>
    </div>
    <div class="home-item3">
      <div class="home-item3-c">
        <div class="t1">打造创新穿透服务</div>
        <div class="t2">
          <div class="t3 home-item6-img1">
            <div class="t4">快速部署</div>
            <div class="t5">安装设备端软件，创建自定义域名访问</div>
          </div>
          <div class="t3 t3a home-item6-img2">
            <div class="t4">安全稳定</div>
            <div class="t5">身份认证、网络认证、设备鉴定、加密传输</div>
          </div>
          <div class="t3 t3b home-item6-img3">
            <div class="t4">可靠保障</div>
            <div class="t5">分布式服务集群，提供SLA保障，自动选择最佳线路</div>
          </div>
          <div class="t3 t3c home-item6-img4">
            <div class="t4">低延时大带宽</div>
            <div class="t5">快速访问、支持大带宽可满足您的很多远程需求</div>
          </div>
        </div>
      </div>
    </div>
    <div class="home-item6">
      <div class="home-item6-c">
        <div class="t1">用节点小宝  体验超凡连接</div>
        <div class="t2">智慧互联 易用快速</div>
        <div class="t3">
          十分期待您来体验产品，无论您是远程开发、远程剪辑、远程下载、远程管理。
          节点小宝超凡的互联传输，助您置身快感之中。
        </div>
      </div>
    </div>
    <div class="home-item7">
      <div class="t1">应用场景</div>
      <div class="btns">
        <span
          :class="{ btn: true, btn1: true, 'btn-active': changeScene == 1 }"
          @mouseover="changeSceneClick(1)"
          >远程Nas桌面</span
        >
        <span
          :class="{ btn: true, 'btn-active': changeScene == 2 }"
          @mouseover="changeSceneClick(2)"
          >远程视频观看</span
        >
        <span
          :class="{ btn: true, 'btn-active': changeScene == 3 }"
          @mouseover="changeSceneClick(3)"
          >远程下载</span
        >
        <span
          :class="{ btn: true, 'btn-active': changeScene == 4 }"
          @mouseover="changeSceneClick(4)"
          >远程ERP-CRM</span
        >
        <span
          :class="{ btn: true, 'btn-active': changeScene == 5 }"
          @mouseover="changeSceneClick(5)"
          >SMB文件服务</span
        >
        <span
          :class="{ btn: true, 'btn-active': changeScene == 6 }"
          @mouseover="changeSceneClick(6)"
          >相册备份</span
        >
      </div>
      <div class="t2-c" v-if="changeScene == 1">
        <div class="t2">
          <div class="t3">远程Nas桌面</div>
          <div class="t4">
            无需公网IP，轻松访问群晖NAS，还原局域网访问习惯，轻松管理您的NAS原IP直达
            http://192.168.0.77:5000。
          </div>
          <a
            class="btn"
            target="_blank"
            href="https://jdxb-ionewu.feishu.cn/wiki/wikcnJDGz4653eCwnKXxXf3MZKT"
            >查看教程</a
          >
        </div>
        <div class="t2-img"></div>
      </div>
      <div class="t2-c" v-if="changeScene == 2">
        <div class="t2">
          <div class="t3">远程视频观看</div>
          <div class="t4">
            开启节点小宝APP为Jeffyfin和Emby 提供连接服务，随时随地享看4K影视。
          </div>
          <a
            class="btn"
            target="_blank"
            href="https://jdxb-ionewu.feishu.cn/wiki/wikcnJDGz4653eCwnKXxXf3MZKT"
            >查看教程</a
          >
        </div>
        <div class="t2-img t2-img2"></div>
      </div>
      <div class="t2-c" v-if="changeScene == 3">
        <div class="t2">
          <div class="t3">远程下载</div>
          <div class="t4">
            通过自定义域名，一键快速访问下载工具，随时随地快速打开qBittorrent、Transmission等下载工具，超快访问速度，助您的下载起飞。
          </div>
          <a
            class="btn"
            target="_blank"
            href="https://jdxb-ionewu.feishu.cn/wiki/wikcnJDGz4653eCwnKXxXf3MZKT"
            >查看教程</a
          >
        </div>
        <div class="t2-img t2-img3"></div>
      </div>
      <div class="t2-c" v-if="changeScene == 4">
        <div class="t2">
          <div class="t3">远程ERP-CRM</div>
          <div class="t4">
            出差了，下班了，在外面的情况常有，节点小宝帮你随时连接公司办公系统，无需公网IP，不限速，还原局域网环境。
          </div>
          <a
            class="btn"
            target="_blank"
            href="https://jdxb-ionewu.feishu.cn/wiki/wikcnJDGz4653eCwnKXxXf3MZKT"
            >查看教程</a
          >
        </div>
        <div class="t2-img t2-img4"></div>
      </div>
      <div class="t2-c" v-if="changeScene == 5">
        <div class="t2">
          <div class="t3">SMB文件服务</div>
          <div class="t4">
            节点小宝组网是模拟局域网习惯，支持ES文件工具、IOS工具，随时都可以打开局域网共享文件，为工作加速。
          </div>
          <a
            class="btn"
            target="_blank"
            href="https://jdxb-ionewu.feishu.cn/wiki/wikcnJDGz4653eCwnKXxXf3MZKT"
            >查看教程</a
          >
        </div>
        <div class="t2-img t2-img5"></div>
      </div>
      <div class="t2-c" v-if="changeScene == 6">
        <div class="t2">
          <div class="t3">相册备份</div>
          <div class="t4">
            将手机相册备份到电脑端或MAC端，也可以备份带存储的NAS上。支持跨网传输，备份速度超快。
            为您珍藏生活精彩瞬间。
          </div>
          <a
            class="btn"
            target="_blank"
            href="https://jdxb-ionewu.feishu.cn/wiki/wikcnJDGz4653eCwnKXxXf3MZKT"
            >查看教程</a
          >
        </div>
        <div class="t2-img t2-img6"></div>
      </div>
    </div>
    <div class="home-item8">
      <div class="t1">只需三步，轻松内网穿透</div>
      <div class="checks">
        <div
          class="item item1"
          :class="{ active1: activeIndex == 3 }"
          @click="changeItem(3)"
        >
          <div class="icon"></div>
          <div class="item-text1">登录节点小宝</div>
        </div>
        <div
          class="item item2"
          :class="{ active2: activeIndex == 1 }"
          @click="changeItem(1)"
        >
          <div class="icon"></div>
          <div class="item-text1">创建服务</div>
        </div>
        <div
          class="item item3"
          :class="{ active3: activeIndex == 2 }"
          @click="changeItem(2)"
        >
          <div class="icon"></div>
          <div class="item-text1">域名访问</div>
        </div>
      </div>
      <div id="pmd">
        <el-carousel
          type="card"
          :autoplay="true"
          arrow="never"
          ref="carousel"
          @change="cardChange"
          :interval="itime"
        >
          <el-carousel-item id="item1">
            <video
              muted="muted"
              loop="loop"
              autoplay="autoplay"
              class="vc"
              v-show="activeIndex == 3"
            >
              <source :src="v1" type="video/mp4" />
            </video>
          </el-carousel-item>
          <el-carousel-item id="item2">
            <video
              muted="muted"
              loop="loop"
              autoplay="autoplay"
              class="vc"
              v-show="activeIndex == 1"
            >
              <source :src="v2" type="video/mp4" />
            </video>
          </el-carousel-item>
          <el-carousel-item id="item3">
            <video
              muted="muted"
              loop="loop"
              autoplay="autoplay"
              class="vc"
              v-show="activeIndex == 2"
            >
              <source :src="v3" type="video/mp4" />
            </video>
          </el-carousel-item>
        </el-carousel>
      </div>
    </div>
    <div class="home-item9">
      <div class="t1">安全策略</div>
      <div class="t2-c">
        <div class="t2">
          <div class="t3">三重认证</div>
          <div class="t4">用户认证</div>
          <div class="t4">设备认证</div>
          <div class="t4">链路认证</div>
        </div>
        <div class="t2 t2a">
          <div class="t3">动态密钥</div>
          <div class="t4">一次一密</div>
          <div class="t4">即时协商</div>
          <div class="t4">256位加密</div>
        </div>
        <div class="t2 t2b">
          <div class="t3">动态域名</div>
          <div class="t4">即时生成</div>
          <div class="t4">立即生效</div>
          <div class="t4">延时销毁</div>
        </div>
        <div class="t2 t2c">
          <div class="t3">来源限定</div>
          <div class="t4">智能识别</div>
          <div class="t4">攻击阻断</div>
          <div class="t4">风控报警</div>
        </div>
        <div class="t2 t2d">
          <div class="t3">链路保障</div>
          <div class="t4">可信认证</div>
          <div class="t4">加密传输</div>
          <div class="t4">延时关闭</div>
        </div>
      </div>
    </div>
    <div class="home-item10">
      <div class="t1">用户评价</div>
      <div class="t2-cc">
        <div class="t2-c">
          <div class="item" v-for="(item, index) in pjArr">
            <img class="item-img" :src="item.imgsrc" />
            <div class="item-t1">{{ item.name }}</div>
            <div class="item-t2">{{ item.pj }}</div>
          </div>
        </div>
      </div>
    </div>
    <div class="home-item11" v-if="false">
      <div class="t1">资讯分享</div>
      <div class="t2-c">
        <div class="item item1">
          <div class="item-img"></div>
          <div class="item-t1">ipv6能实现穿透内网吗？</div>
          <div class="item-t2">
            随着互联网的不断发展，IP地址资源逐渐紧张，IPv4的局限性愈发凸显。IPv6的出现，为我们提供了Pv6的出现，为我们提供了Pv6的出现，为我们提供了更...
          </div>
          <div class="item-t3">2024-03-28 10:00:00</div>
        </div>
        <div class="item item2">
          <div class="item-img"></div>
          <div class="item-t1">ipv6能实现穿透内网吗？</div>
          <div class="item-t2">
            随着互联网的不断发展，IP地址资源逐渐紧张，IPv4的局限性愈发凸显。IPv6的出现，为我们提供了Pv6的出现，为我们提供了Pv6的出现，为我们提供了更...
          </div>
          <div class="item-t3">2024-03-28 10:00:00</div>
        </div>
        <div class="item">
          <div class="item-img"></div>
          <div class="item-t1">ipv6能实现穿透内网吗？</div>
          <div class="item-t2">
            随着互联网的不断发展，IP地址资源逐渐紧张，IPv4的局限性愈发凸显。IPv6的出现，为我们提供了Pv6的出现，为我们提供了Pv6的出现，为我们提供了更...
          </div>
          <div class="item-t3">2024-03-28 10:00:00</div>
        </div>
      </div>
      <div class="t3-c">
        <div class="t3">查看更多</div>
        <div class="t3-img"></div>
      </div>
    </div>
    <div class="home-item12">
      <div class="t1-c">
        <div class="t1">远程访问用小宝，您远程的理想伴侣。</div>
        <div class="btn" @click="jumpDownload">立即下载</div>
      </div>
    </div>
    <Foot></Foot>
  </div>
</template>

<script>
import Foot from "@/components/Foot.vue";
import a1 from "../assets/v4/home65.png";
import a2 from "../assets/v4/home38.png";
import a3 from "../assets/v4/home66.png";
import a4 from "../assets/v4/home67.png";
import a5 from "../assets/v4/home68.png";
import a6 from "../assets/v4/home69.png";
import a7 from "../assets/v4/home70.png";
import a8 from "../assets/v4/home71.png";
import a9 from "../assets/v4/home72.png";
import a10 from "../assets/v4/home73.png";
import a11 from "../assets/v4/home74.png";
import a12 from "../assets/v4/home75.png";

export default {
  name: "Home",
  components: {
    Foot,
  },
  data: function () {
    return {
      pjArr: [
        {
          name: "一条咸鱼",
          pj: "最让我觉得厉害的是自定义域名内网转发功能，实在是太便捷了。要知道一个内网穿透就可以让人操作半天，搞一个域名访问又是半天的事。结果小宝这里，我第一次用也只用了1分钟不到。。。完全颠覆了我对这两个操作的固有印象。",
          imgsrc: a1,
        },
        {
          name: "蜡笔",
          pj: "你们的产品太牛逼了，好方便啊，有他域名访问，以后连客户端都不用装了。",
          imgsrc: a2,
        },
        {
          name: "热心市民林先生",
          pj: "节点小宝智能组网速度有点牛，看电影怎么拉都不卡。",
          imgsrc: a3,
        },
        {
          name: "柊下桜",
          pj: "这可能是我一直苦苦追求的理想中的工具！我就想要在外面用笔记本也可以访问家里的samba。",
          imgsrc: a4,
        },
        {
          name: "吴刚",
          pj: "异地组网的功能非常好，NAS一般都支持映射网络驱动器，只要启动节点小宝，远程的NAS就可以当做本机硬盘一样用了。",
          imgsrc: a5,
        },
        {
          name: "Jeemzz",
          pj: "节点小宝的稳定性令人印象深刻，无论是大规模数据传输还是长时间稳定运行，都能保持出色的性能，非常值得信赖。",
          imgsrc: a6,
        },
        {
          name: "Winston",
          pj: "数据安全是我最关心的，节点小宝的点对点安全直连技术让我非常放心。每次使用，都感觉数据传输又快又稳。",
          imgsrc: a7,
        },
        {
          name: "玉宇琼楼",
          pj: "自从用了节点小宝，我的远程工作变得轻松多了！异地组网和内网穿透功能太实用了，真心推荐给大家！",
          imgsrc: a8,
        },
        {
          name: "จุ๊บ惜情จุ๊บ",
          pj: "出差在外，还能随时管理公司的内网资源，这都要归功于节点小宝的异地组网功能。真心感谢节点小宝团队！",
          imgsrc: a9,
        },
        {
          name: "Karl Yau",
          pj: "强烈推荐节点小宝给所有需要远程管理的朋友。它的内网穿透功能真的太强大了，无论在哪里都能轻松访问公司内网。",
          imgsrc: a10,
        },
        {
          name: "起风了",
          pj: "高效、安全、稳定，这是我对节点小宝的评价。它不仅满足了我的工作需求，还给了我超出预期的体验，感谢节点小宝。",
          imgsrc: a11,
        },
        {
          name: "Timymood",
          pj: "相较于其他同类产品，节点小宝的价格非常合理。它的功能和性能都超出了我的预期，而价格却相对亲民。这种高性价比让我感到非常满意。",
          imgsrc: a12,
        },
      ],
      v1: require("../assets/v4/v1.mp4"),
      v2: require("../assets/v4/v2.mp4"),
      v3: require("../assets/v4/v3.mp4"),
      p1: require("../assets/img79.png"),
      p2: require("../assets/img80.png"),
      p3: require("../assets/img81.png"),
      active: 2,
      businessName: "",
      contactName: "",
      contactPhone: "",
      message: "",
      msg: "",
      activeIndex: 3,
      itime: 9000,
      navType: "ydzw",
      changeScene: 1,
    };
  },
  methods: {
    changeSceneClick: function (data) {
      this.changeScene = data;
    },
    jumpDownload: function () {
      this.$router.push({
        path: "download",
      });
    },
    changeNavType: function (type) {
      this.navType = type;
    },
    cardChange: function (data) {
      let _that = this;

      if (data == 1) {
        _that.activeIndex = 1;
        // _that.itime = 9000;
        _that.$nextTick(() => {
          let item1 = document.getElementById("item1");
          item1.style =
            "transform:translateX(" + -100 / 192 + "rem) scale(0.75)";

          let item2 = document.getElementById("item2");
          item2.style =
            "height: " +
            500 / 192 +
            "rem;transform:translateX(" +
            240 / 192 +
            "rem) scale(1);border: " +
            15 / 192 +
            "rem solid rgba(78,76,255,0.4);";

          let item3 = document.getElementById("item3");
          item3.style =
            "transform:translateX(" + 580 / 192 + "rem) scale(0.75)";
        });
      } else if (data == 2) {
        _that.activeIndex = 2;
        // _that.itime = 7000;
        _that.$nextTick(() => {
          let item1 = document.getElementById("item1");
          item1.style =
            "transform:translateX(" + 580 / 192 + "rem) scale(0.75)";

          let item2 = document.getElementById("item2");
          item2.style =
            "transform:translateX(" + -100 / 192 + "rem) scale(0.75)";

          let item3 = document.getElementById("item3");
          item3.style =
            "height: " +
            500 / 192 +
            "rem;transform:translateX(" +
            240 / 192 +
            "rem) scale(1);border: " +
            15 / 192 +
            "rem solid rgba(78,76,255,0.4);";
        });
      } else {
        _that.activeIndex = 3;
        // _that.itime = 5000;
        _that.$nextTick(() => {
          let item1 = document.getElementById("item1");
          item1.style =
            "height: " +
            500 / 192 +
            "rem;transform:translateX(" +
            240 / 192 +
            "rem) scale(1);border: " +
            15 / 192 +
            "rem solid rgba(78,76,255,0.4);";

          let item2 = document.getElementById("item2");
          item2.style =
            "transform:translateX(" + 580 / 192 + "rem) scale(0.75)";

          let item3 = document.getElementById("item3");
          item3.style =
            "transform:translateX(" + -100 / 192 + "rem) scale(0.75)";
        });
      }
      // _that.changeStyle();
    },
    changeItem: function (index) {
      let _that = this;
      _that.activeIndex = index;
      _that.$refs.carousel.setActiveItem(index);
    },
    leftClick: function () {
      let _that = this;
      if (_that.active > 1) {
        _that.active = --_that.active;
      } else {
        _that.active = 8;
      }
    },
    rightClick: function () {
      let _that = this;
      if (_that.active < 8) {
        _that.active = ++_that.active;
      } else {
        _that.active = 1;
      }
    },
    activeClick: function (data) {
      let _that = this;
      _that.active = data;
    },
    inputFunc: function () {
      let _that = this;
      if (_that.contactPhone.length > 11) {
        _that.contactPhone = _that.contactPhone.slice(0, 11);
      }
    },
    submitClick: function () {
      let _that = this;
      if (
        !_that.businessName ||
        !_that.contactName ||
        !_that.contactPhone ||
        !_that.message
      ) {
        _that.msg = "请填写详细信息";
        setTimeout(function () {
          _that.msg = "";
        }, 1500);
        return;
      }
      let params = {
        businessName: _that.businessName,
        contactName: _that.contactName,
        contactPhone: _that.contactPhone,
        message: _that.message,
      };
      fetch("https://jdxb.ionewu.com/jdb/cooperate/apply/insert", {
        method: "post",
        headers: {
          "Content-type": "application/json",
        },
        body: JSON.stringify(params),
        credentials: "omit",
        mode: "cors",
      })
        .then((data) => {
          console.log(data);
        })
        .then((response) => {
          _that.msg = "保存成功";
          setTimeout(function () {
            _that.msg = "";
          }, 1500);
          console.log(response);
        });
    },
    changeStyle: function () {
      let _that = this;
      if (_that.activeIndex == 3) {
        _that.$nextTick(() => {
          let item1 = document.getElementById("item1");
          item1.style =
            "height: " +
            500 / 192 +
            "rem;transform:translateX(" +
            240 / 192 +
            "rem) scale(1);border: " +
            15 / 192 +
            "rem solid rgba(78,76,255,0.4);";

          let item2 = document.getElementById("item2");
          item2.style =
            "transform:translateX(" + 580 / 192 + "rem) scale(0.75)";

          let item3 = document.getElementById("item3");
          item3.style =
            "transform:translateX(" + -100 / 192 + "rem) scale(0.75)";
        });
      } else if (_that.activeIndex == 1) {
        _that.$nextTick(() => {
          let item1 = document.getElementById("item1");
          item1.style =
            "transform:translateX(" + -100 / 192 + "rem) scale(0.75)";

          let item2 = document.getElementById("item2");
          item2.style =
            "height: " +
            500 / 192 +
            "rem;transform:translateX(" +
            240 / 192 +
            "rem) scale(1);border: " +
            15 / 192 +
            "rem solid rgba(78,76,255,0.4);";

          let item3 = document.getElementById("item3");
          item3.style =
            "transform:translateX(" + 580 / 192 + "rem) scale(0.75)";
        });
      } else {
        _that.$nextTick(() => {
          let item1 = document.getElementById("item1");
          item1.style =
            "transform:translateX(" + 580 / 192 + "rem) scale(0.75)";

          let item2 = document.getElementById("item2");
          item2.style =
            "transform:translateX(" + -100 / 192 + "rem) scale(0.75)";

          let item3 = document.getElementById("item3");
          item3.style =
            "height: " +
            500 / 192 +
            "rem;transform:translateX(" +
            240 / 192 +
            "rem) scale(1);border: " +
            15 / 192 +
            "rem solid rgba(78,76,255,0.4);";
        });
      }
    },
  },
  mounted() {
    // this.changeStyle();

    this.$nextTick(() => {
      let item1 = document.getElementById("item1");
      item1.style =
        "height: " +
        500 / 192 +
        "rem;transform:translateX(" +
        240 / 192 +
        "rem) scale(1);border: " +
        15 / 192 +
        "rem solid rgba(78,76,255,0.4);";

      let item2 = document.getElementById("item2");
      item2.style = "transform:translateX(" + 580 / 192 + "rem) scale(0.75)";

      let item3 = document.getElementById("item3");
      item3.style = "transform:translateX(" + -100 / 192 + "rem) scale(0.75)";
    });
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="less">
.home {

  .home-item1 {
    width: 100%;
    background-image: url("../assets/v4/home1.png");
    background-size: 100% 100%;
    background-repeat: no-repeat;
    height: 650px;

    .home-item1-c {
      margin: 0 auto;
      width: 1280px;
      height: 650px;
      overflow: hidden;

      .t1 {
        margin-top: 108px;
        font-size: 48px;
        font-weight: bold;
        color: #17171b;
      }
      .t2 {
        margin-top: 16px;
        width: 600px;
        color: #17171b;
        line-height: 30px;
        font-size: 18px;
      }
      .t2a{
        margin-top: 0;
      }
      .t3 {
        margin-top: 24px;
        font-size: 18px;
        line-height: 20px;
        .t3-item {
          position: relative;
          display: inline-block;
          padding-left: 26px;
        }
        .t3-item::before {
          position: absolute;
          left: 0;
          top: -1px;
          content: "";
          background-image: url("../assets/v4/home2.svg");
          width: 20px;
          height: 20px;
          background-size: 100% 100%;
          background-repeat: no-repeat;
        }
        .t3-item2 {
          margin-left: 20px;
        }
      }
      .btn-free {
        display: block;
        margin-top: 32px;
        width: 160px;
        height: 44px;
        border-radius: 6px;
        background-color: #4e4cff;
        line-height: 44px;
        color: #ffffff;
        font-size: 16px;
        text-align: center;
        cursor: pointer;
        font-weight: 500;
        text-decoration: none;
      }
      .btn-free:hover {
        opacity: 0.9;
      }
      .btn-download {
        text-align: center;
        margin-top: 62px;
        width: 1280px;
        height: 120px;
        border-radius: 16px;
        background: rgba(255, 255, 255, 0.75);
        font-size: 18px;
        box-shadow: rgba(204, 204, 204, 0.16) 0px 16px 30px;
        backdrop-filter: blur(15px);
        .btn-d {
          margin-top: 25px;
          display: inline-block;
          margin-right: 8px;
          width: 170px;
          height: 70px;
          line-height: 70px;
          cursor: pointer;
          text-decoration: none;
          color: #17171b;

          .btn-img {
            display: inline-block;
            margin-top: 13px;
            margin-right: 6px;
            background-image: url("../assets/v4/home3.svg");
            width: 44px;
            height: 44px;
            background-size: 100% 100%;
            background-repeat: no-repeat;
            vertical-align: top;
          }
          .btn-img2 {
            background-image: url("../assets/v4/home4.svg");
          }
          .btn-img3 {
            background-image: url("../assets/v4/home45.svg");
          }
          .btn-img4 {
            background-image: url("../assets/v4/home6.svg");
          }
          .btn-img5 {
            background-image: url("../assets/v4/home7.svg");
          }
          .btn-img6 {
            background-image: url("../assets/v4/home8.svg");
          }
          .btn-img7 {
            background-image: url("../assets/v4/home9.svg");
          }
        }

        .btn-d:hover {
          color: #4e4cff;
          border-radius: 10px;
          background-color: #e4e4fd;
          .btn-img1 {
            background-image: url("../assets/v4/home46.svg");
          }
          .btn-img2 {
            background-image: url("../assets/v4/home47.svg");
          }
          .btn-img3 {
            background-image: url("../assets/v4/home5.svg");
          }
          .btn-img4 {
            background-image: url("../assets/v4/home48.svg");
          }
          .btn-img5 {
            background-image: url("../assets/v4/home49.svg");
          }
          .btn-img6 {
            background-image: url("../assets/v4/home50.svg");
          }
          .btn-img7 {
            background-image: url("../assets/v4/home51.svg");
          }
        }
        .btn-d6 {
          margin-left: 0px;
        }
      }
    }
  }
  .home-item2 {
    width: 100%;
    height: 620px;
    background-color: #ffffff;

    .home-item2-c {
      margin: 0 auto;
      width: 1280px;
      height: 620px;
      overflow: hidden;
      .t1 {
        margin-top: 70px;
        text-align: center;
        color: #17171b;
        font-size: 40px;
        font-weight: bold;
      }
      .t2 {
        margin-top: 40px;
        position: relative;
        width: 1280px;
        .t2-img {
          position: absolute;
          top: 0;
          left: 0;
          width: 640px;
          height: 360px;
          background-image: url("../assets/v4/home10.png");
          background-size: 100% 100%;
          background-repeat: no-repeat;
        }
        .t3 {
          margin-left: 700px;
          height: 360px;
          overflow: hidden;
          .t4 {
            margin-top: 32px;
            font-size: 30px;
            line-height: 42px;
            color: #17171b;
            font-weight: 500;
          }
          .t5 {
            margin-top: 18px;
            font-size: 18px;
            line-height: 30px;
            color: #57576c;
          }
          .btns {
            margin-top: 30px;

            .btn {
              display: inline-block;
              width: 160px;
              height: 44px;
              line-height: 44px;
              border-radius: 6px;
              border: 1px solid #4e4cff;
              box-sizing: border-box;
              color: #4e4cff;
              font-size: 16px;
              text-align: center;
              cursor: pointer;
              text-decoration: none;
            }
            .btn2,
            .btn3 {
              margin-left: 16px;
            }
            .btn:hover {
              background-color: #4e4cff;
              color: #ffffff;
            }
            .btn1 {
              background-color: #4e4cff;
              color: #ffffff;
              font-weight: 500;
            }
            .btn1:hover {
              opacity: 0.9;
            }
          }
        }
      }
    }
  }
  .home-item3 {
    width: 100%;
    height: 500px;
    background-color: #f6f8ff;

    .home-item3-c {
      margin: 0 auto;
      width: 1280px;
      height: 500px;
      overflow: hidden;

      .t1 {
        margin-top: 70px;
        text-align: center;
        color: #17171b;
        font-size: 40px;
        font-weight: bold;
      }
      .t2 {
        .t3 {
          position: relative;
          margin-top: 70px;
          padding-top: 94px;
          display: inline-block;
          min-height: 200px;
          overflow: hidden;
          .t4 {
            color: #23232a;
            font-size: 28px;
          }
          .t5 {
            margin-top: 20px;
            color: #57576c;
            font-size: 18px;
            width: 220px;
          }
        }
        .t3::before {
          content: "";
          position: absolute;
          top: 0;
          left: 0;
          width: 60px;
          height: 66px;
          background-image: url("../assets/v4/home11.svg");
          background-size: 100% 100%;
          background-repeat: no-repeat;
        }
        .t3a::before {
          background-image: url("../assets/v4/home12.svg");
        }
        .t3b::before {
          width: 66px;
          height: 66px;
          background-image: url("../assets/v4/home13.svg");
        }
        .t3c::before {
          background-image: url("../assets/v4/home14.svg");
        }
        .t3a,
        .t3b,
        .t3c {
          margin-left: 130px;
        }

        .home-item6-img1::before {
          height: 60px;
          width: 66px;
          background-image: url("../assets/v4/home18.svg");
        }
        .home-item6-img2::before {
          background-image: url("../assets/v4/home19.svg");
        }
        .home-item6-img3::before {
          width: 60px;
          height: 66px;
          background-image: url("../assets/v4/home20.svg");
        }
        .home-item6-img4::before {
          background-image: url("../assets/v4/home21.svg");
        }
      }
    }
  }
  .home-item4 {
    width: 100%;
    height: 700px;
    background-image: url("../assets/v4/home63.png");
    background-repeat: no-repeat;
    background-size: 100% 100%;

    .home-item4-c {
      margin: 0 auto;
      width: 1280px;
      height: 700px;
      overflow: hidden;

      .t1 {
        margin-top: 70px;
        text-align: center;
        font-size: 40px;
        font-weight: bold;
        color: #ffffff;
      }
      .t2 {
        float: left;
        margin-top: 60px;
        width: 620px;
        height: 420px;
        border-radius: 20px;
        background: rgba(255, 255, 255, 0.05);
        text-align: center;
        overflow: hidden;
        .title1 {
          margin-top: 36px;
          font-size: 30px;
          color: #ffffff;
          font-weight: bold;
        }
        .title2 {
          margin-top: 10px;
          font-size: 18px;
          color: #ffffff;
        }
      }
      .t2a {
        margin-left: 40px;
      }
      .t2-img {
        margin: 0 auto;
        margin-top: 54px;
        width: 472px;
        height: 186px;
        background-image: url("../assets/v4/home15.png");
        background-size: 100% 100%;
        background-repeat: no-repeat;
      }
      .t2-img2 {
        margin-top: 68px;
        width: 420px;
        height: 160px;
        background-image: url("../assets/v4/home16.svg");
        background-size: 100% 100%;
        background-repeat: no-repeat;
      }
    }
  }
  .home-item5 {
    width: 100%;
    height: 620px;
    background-color: #ffffff;

    .home-item5-c {
      margin: 0 auto;
      width: 1280px;
      height: 620px;
      overflow: hidden;

      .t1 {
        margin-top: 70px;
        text-align: center;
        font-size: 40px;
        font-weight: bold;
        color: #17171b;
      }
      .t2 {
        position: relative;

        .title1 {
          padding-top: 75px;
          font-size: 30px;
          font-weight: 500;
          color: #23232a;
        }
        .title2 {
          margin-top: 20px;
          font-size: 18px;
          color: #57576c;
          line-height: 30px;
          width: 570px;
        }
        .btn1 {
          float: left;
          margin-top: 28px;
          width: 160px;
          height: 44px;
          line-height: 44px;
          box-sizing: border-box;
          background-color: #4e4cff;
          font-size: 16px;
          color: #ffffff;
          border-radius: 6px;
          text-align: center;
          text-decoration: none;
        }
        .btn1b,
        .btn1c {
          margin-left: 16px;
        }
        .btn1:hover {
          cursor: pointer;
          opacity: 0.9;
        }
        .t2-img {
          position: absolute;
          right: 0;
          top: 58px;
          width: 640px;
          height: 360px;
          background-image: url("../assets/v4/home17.png");
          background-size: 100% 100%;
          background-repeat: no-repeat;
        }
      }
      .t2:after {
        content: "";
        display: block; /* 设置添加子元素为块级元素 */
        height: 0; /* 设置添加的子元素的高度0 */
        visibility: hidden; /* 设置添加子元素看不见 */
        clear: both; /* 设置clear：both */
      }
    }
  }
  .home-item6 {
    width: 100%;
    height: 800px;
    background-color: #17171b;

    .home-item6-c {
      margin: 0 auto;
      height: 800px;
      width: 100%;
      overflow: hidden;
      background-image: url("../assets/v4/home22.png");
      background-size: 100% 100%;
      background-repeat: no-repeat;

      .t1 {
        margin-top: 70px;
        text-align: center;
        font-size: 40px;
        font-weight: bold;
        color: #ffffff;
      }
      .t2 {
        margin-top: 16px;
        text-align: center;
        font-size: 32px;
        font-weight: bold;
        color: #ffffff;
      }
      .t3 {
        margin: 0 auto;
        margin-top: 40px;
        width: 700px;
        text-align: center;
        font-size: 20px;
        color: #ffffff;
      }
    }
  }
  .home-item7 {
    width: 100%;
    height: 760px;
    background-color: #f6f8ff;
    overflow: hidden;
    .t1 {
      margin-top: 66px;
      font-size: 40px;
      font-weight: bold;
      color: #2f2f38;
      text-align: center;
    }
    .btns {
      margin: 0 auto;
      width: 1280px;
      text-align: center;

      .btn {
        display: inline-block;
        padding-top: 40px;
        padding-bottom: 40px;
        padding-left: 52px;
        padding-right: 52px;
        font-size: 18px;
        color: #57576c;
        cursor: pointer;
      }
      .btn-active {
        position: relative;
        color: #17171b;
        font-weight: 500;
      }
      .btn-active::after {
        content: "";
        position: absolute;
        top: 70px;
        left: 50%;
        transform: translate(-50%, 0);
        width: 40px;
        height: 4px;
        border-radius: 2px;
        background-color: #4e4cff;
      }
    }
    .t2-c {
      margin: 0 auto;
      width: 1280px;
      height: 420px;
      border-radius: 20px;
      box-shadow: 0px 4px 40px 0px #e8ecf9;
      overflow: hidden;

      .t2 {
        float: left;
        margin-left: 78px;
        margin-top: 88px;
        .t3 {
          font-size: 30px;
          font-weight: bold;
          color: #2f2f38;
        }
        .t4 {
          margin-top: 18px;
          font-size: 18px;
          color: #17171b;
          line-height: 30px;
          width: 370px;
        }
        .btn {
          display: inline-block;
          margin-top: 40px;
          width: 160px;
          height: 44px;
          line-height: 44px;
          border-radius: 6px;
          background-color: #4e4cff;
          font-size: 16px;
          font-weight: 500;
          color: #ffffff;
          text-align: center;
          cursor: pointer;
          text-decoration: none;
        }
        .btn:hover {
          opacity: 0.9;
        }
      }
      .t2-img {
        float: right;
        width: 680px;
        height: 420px;
        background-image: url("../assets/v4/home23.png");
        background-size: 100% 100%;
        background-repeat: no-repeat;
      }
      .t2-img2 {
        background-image: url("../assets/v4/home52.png");
      }
      .t2-img3 {
        background-image: url("../assets/v4/home53.png");
      }
      .t2-img4 {
        background-image: url("../assets/v4/home54.png");
      }
      .t2-img5 {
        background-image: url("../assets/v4/home55.png");
      }
      .t2-img6 {
        background-image: url("../assets/v4/home56.png");
      }
    }
  }
  .home-item8 {
    height: 1000px;
    box-sizing: border-box;
    background: linear-gradient(90deg, #e1e1ff 0%, #e8e1ff 50%, #ffefe4 100%);
    overflow: hidden;
    .t1 {
      margin-top: 70px;
      text-align: center;
      font-size: 40px;
      font-weight: bold;
      color: #2f2f38;
    }

    .checks {
      position: relative;
      margin: 0 auto;
      margin-top: 64px;
      margin-bottom: 48px;
      text-align: center;
      width: 1140px;
      height: 110px;

      .item {
        width: 160px;
        cursor: pointer;
        text-align: center;
        .icon {
          display: inline-block;
          width: 60px;
          height: 60px;
          background-image: url("../assets/v4/home24.svg");
          background-size: 100% 100%;
          background-repeat: no-repeat;
        }

        .item-text1 {
          margin-top: 15px;
          font-size: 24px;
          font-weight: 500;
          color: #2f2f38;
        }
      }
      .item1 {
        position: absolute;
        z-index: 2;
        left: 0px;
        top: 0;
      }

      .item2 {
        position: absolute;
        z-index: 2;
        left: 490px;
        top: 0;

        .icon {
          background-image: url("../assets/v4/home25.svg");
        }
      }
      .item3 {
        position: absolute;
        z-index: 2;
        right: 0px;
        top: 0;

        .icon {
          background-image: url("../assets/v4/home26.svg");
        }
      }
      .active1,
      .active2,
      .active3 {
        .item-text1 {
          color: #4e4cff;
        }
      }
      .active1 {
        .icon {
          background-image: url("../assets/v4/home27.svg");
          background-size: 100% 100%;
          background-repeat: no-repeat;
        }
      }
      .active2 {
        .icon {
          background-image: url("../assets/v4/home28.svg");
          background-size: 100% 100%;
          background-repeat: no-repeat;
        }
      }
      .active3 {
        .icon {
          background-image: url("../assets/v4/home29.svg");
          background-size: 100% 100%;
          background-repeat: no-repeat;
        }
      }
    }
    .checks::before {
      position: absolute;
      top: 30px;
      left: 70px;
      z-index: 1;
      content: "";
      width: 1000px;
      border-top: 1px dashed #4e4cff;
    }
  }
  .home-item9 {
    width: 100%;
    height: 600px;
    overflow: hidden;
    background-image: url("../assets/v4/home77.png");
    background-size: 100% 100%;
    background-repeat: no-repeat;
    .t1 {
      margin-top: 80px;
      font-size: 40px;
      font-weight: bold;
      color: #ffffff;
      text-align: center;
    }
    .t2-c {
      margin: 0 auto;
      margin-top: 58px;
      width: 1280px;
      height: 300px;
      text-align: center;
      .t2 {
        position: relative;
        float: left;
        width: 232px;
        height: 300px;
        border-radius: 20px;
        background-color: rgba(255, 255, 255, 0.05);
        cursor: pointer;

        .t3 {
          margin-top: 122px;
          margin-bottom: 10px;
          font-size: 24px;
          font-weight: 500;
          color: #ffffff;
        }
        .t4 {
          font-size: 16px;
          line-height: 30px;
          color: #9090a2;
        }
      }
      .t2:hover {
        background-color: rgba(255, 255, 255, 0.15);
      }
      .t2::before {
        content: "";
        position: absolute;
        top: 40px;
        left: 88px;
        width: 56px;
        height: 56px;
        background-image: url("../assets/v4/home33.svg");
        background-size: 100% 100%;
        background-repeat: no-repeat;
      }
      .t2a::before {
        background-image: url("../assets/v4/home34.svg");
      }
      .t2b::before {
        background-image: url("../assets/v4/home35.svg");
      }
      .t2c::before {
        background-image: url("../assets/v4/home36.svg");
      }
      .t2d::before {
        background-image: url("../assets/v4/home37.svg");
      }
      .t2a,
      .t2b,
      .t2c,
      .t2d {
        margin-left: 30px;
      }
    }
  }
  .home-item10 {
    width: 100%;
    height: 620px;
    background-color: #ffffff;
    overflow: hidden;
    .t1 {
      margin-top: 76px;
      font-size: 40px;
      font-weight: bold;
      color: #17171b;
      text-align: center;
    }
    .t2-cc {
      margin-top: 50px;
      position: relative;
      height: 370px;
      width: 100%;
    }
    .t2-cc::before {
      content: "";
      position: absolute;
      width: 272px;
      height: 370px;
      background: linear-gradient(
        90.5deg,
        rgb(255, 255, 255) 0.36%,
        rgba(255, 255, 255, 0) 99.49%
      );
      top: 0px;
      left: 0px;
      z-index: 2;
    }
    .t2-cc::after {
      content: "";
      position: absolute;
      width: 272px;
      height: 370px;
      background: linear-gradient(
        90.5deg,
        rgba(255, 255, 255, 0) 0.36%,
        rgb(255, 255, 255) 99.49%
      );
      top: 0px;
      right: 0px;
      z-index: 2;
    }
    .t2-c {
      width: 4300px;
      /*width: 6048px;*/
      height: 370px;
      animation: 28s linear 0s infinite normal none running move;
      .item {
        float: left;
        margin-left: 15px;
        margin-right: 15px;
        width: 304px;
        height: 370px;
        border-radius: 20px;
        border: 1px solid #e5e6eb;
        overflow: hidden;
        box-sizing: border-box;

        .item-img {
          display: block;
          margin: 0 auto;
          margin-top: 46px;
          width: 80px;
          height: 80px;
        }
        .item-t1 {
          margin-top: 16px;
          font-size: 24px;
          font-weight: bold;
          color: #17171b;
          text-align: center;
        }
        .item-t2 {
          margin: 0 auto;
          margin-top: 16px;
          text-align: center;
          font-size: 16px;
          color: #57576c;
          width: 240px;
        }
      }
    }
    @keyframes move {
      100% {
        /*transform: translateX(-4032px);*/
        transform: translateX(-2100px);
      }
    }
  }
  .home-item11 {
    width: 100%;
    height: 720px;
    background-color: #ffffff;
    overflow: hidden;
    .t1 {
      margin-top: 76px;
      font-size: 40px;
      font-weight: bold;
      color: #17171b;
      text-align: center;
    }
    .t2-c {
      margin: 0 auto;
      width: 1280px;
      .item {
        float: left;

        .item-img {
          width: 400px;
          height: 228px;
          border-radius: 20px;
          background-image: url("../assets/v4/home40.png");
          background-size: 100% 100%;
          background-repeat: no-repeat;
        }
        .item-t1 {
          margin-top: 15px;
          font-size: 20px;
          font-weight: 500;
          line-height: 30px;
          color: #4f4cff;
        }
        .item-t2 {
          margin-top: 10px;
          font-size: 14px;
          line-height: 22px;
          color: #57576c;
          width: 394px;
        }
        .item-t3 {
          position: relative;
          margin-top: 32px;
          padding-left: 20px;
          font-size: 14px;
          line-height: 16px;
          color: #9090a2;
        }
        .item-t3::before {
          position: absolute;
          left: 0;
          top: 0;
          content: "";
          width: 16px;
          height: 16px;
          background-image: url("../assets/v4/home39.svg");
          background-size: 100% 100%;
          background-repeat: no-repeat;
        }
      }
      .item1,
      .item2 {
        margin-right: 40px;
      }
    }
    .t2-c:after {
      content: "";
      display: block;
      height: 0;
      visibility: hidden;
      clear: both;
    }

    .t3-c {
      font-size: 16px;
      color: #4e5969;
      text-align: center;
      cursor: pointer;
      .t3 {
        margin-top: 40px;
        display: inline-block;
        padding-right: 8px;
        vertical-align: bottom;
        cursor: pointer;
        line-height: 24px;
      }
      .t3-img {
        display: inline-block;
        margin-top: 40px;
        vertical-align: bottom;
        padding-top: 8px;
        padding-bottom: 8px;
        width: 8px;
        height: 8px;
        background-image: url("../assets/v4/home41.svg");
        background-size: 100% 100%;
        background-repeat: no-repeat;
      }
    }
  }
  .home-item12 {
    width: 100%;
    height: 160px;
    background-image: url("../assets/v4/home42.png");
    background-size: 100% 100%;
    background-repeat: no-repeat;
    overflow: hidden;

    .t1-c {
      position: relative;
      margin: 0 auto;
      width: 1280px;
      height: 160px;
      overflow: hidden;
      .t1 {
        /*margin-top: 44px;*/
        font-size: 36px;
        line-height: 160px;
        font-weight: 500;
        color: #ffffff;
      }
      .t2 {
        margin-top: 6px;
        font-size: 16px;
        font-weight: bold;
        line-height: 30px;
        color: #ffffff;
      }
      .btn {
        position: absolute;
        top: 55px;
        right: 0;
        width: 160px;
        height: 44px;
        line-height: 44px;
        border-radius: 6px;
        background-color: #ffffff;
        font-size: 16px;
        font-weight: 500;
        color: #4e4cff;
        text-align: center;
        cursor: pointer;
      }
    }
  }
  /*.home-item13{*/
  /*  height: 2930px;*/
  /*  width: 100%;*/
  /*  background-color: #ffffff;*/
  /*  overflow: hidden;*/
  /*  .t1{*/
  /*    margin-top: 70px;*/
  /*    font-size: 40px;*/
  /*    font-weight: bold;*/
  /*    color: #17171B;*/
  /*    text-align: center;*/
  /*    line-height: 58px;*/
  /*  }*/
  /*  .item{*/
  /*    margin: 0 auto;*/
  /*    height: 460px;*/
  /*    width: 1280px;*/
  /*    overflow: hidden;*/

  /*    .i1{*/
  /*      margin-top: 86px;*/
  /*      font-size: 36px;*/
  /*      font-weight: bold;*/
  /*      color: #17171B;*/
  /*      line-height: 52px;*/
  /*    }*/
  /*    .i2{*/
  /*      margin-top: 12px;*/
  /*      font-size: 20px;*/
  /*      color: #17171B;*/
  /*      line-height: 28px;*/
  /*    }*/
  /*    .i3{*/
  /*      font-size: 18px;*/
  /*      color: #57576C;*/
  /*      line-height: 30px;*/
  /*    }*/
  /*    .i3a{*/
  /*      margin-top: 16px;*/
  /*    }*/
  /*    .i-btn{*/
  /*      margin-top: 30px;*/
  /*      width: 140px;*/
  /*      height: 44px;*/
  /*      line-height: 44px;*/
  /*      border-radius: 6px;*/
  /*      background-color: #4E4CFF;*/
  /*      text-align: center;*/
  /*      color: #FFFFFF;*/
  /*      cursor: pointer;*/
  /*      font-size: 16px;*/
  /*    }*/
  /*  }*/
  /*  .item1{*/
  /*    padding-left: 800px;*/
  /*    margin-top: 52px;*/
  /*    background-image: url("../assets/v4/home81.png");*/
  /*    background-position: left center;*/
  /*    background-size: 680px 460px;*/
  /*    background-repeat: no-repeat;*/
  /*    box-sizing: border-box;*/
  /*  }*/
  /*  .item2{*/
  /*    margin-top: 40px;*/
  /*    background-image: url("../assets/v4/home82.png");*/
  /*    background-position: right center;*/
  /*    background-size: 720px 460px;*/
  /*    background-repeat: no-repeat;*/
  /*    box-sizing: border-box;*/
  /*  }*/
  /*  .item3{*/
  /*    background-image: url("../assets/v4/home83.png");*/
  /*    background-size: 600px 460px;*/
  /*  }*/
  /*  .item4{*/
  /*    background-image: url("../assets/v4/home84.png");*/
  /*    background-size: 620px 460px;*/
  /*  }*/
  /*}*/
}
</style>
<style lang="less">
#pmd {
  margin: 0 auto;
  width: 1280px;

  .vc {
    width: 800px;
    height: 500px;
  }
  .el-carousel__container {
    height: 530px;
  }
  .el-carousel__item {
    border-radius: 16px;
    background-position: center;
    background-size: 100% 100%;
    background-repeat: no-repeat;
  }
  .el-carousel__item:nth-child(1) {
    background-image: url("../assets/v4/home30.png");
  }
  .el-carousel__item:nth-child(2) {
    background-image: url("../assets/v4/home31.png");
  }
  .el-carousel__item:nth-child(3) {
    background-image: url("../assets/v4/home32.png");
  }
  .el-carousel__item--card {
    width: 800px;
  }

  .el-carousel__mask {
    background-color: transparent;
  }
  .el-carousel__indicators--outside {
    position: absolute;
    top: 0;
    left: 0;
    bottom: auto;
    button {
      background-color: transparent;
    }
  }
}
</style>
