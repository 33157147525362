import { render, staticRenderFns } from "./DownloadV5.vue?vue&type=template&id=5752f9da&scoped=true"
import script from "./DownloadV5.vue?vue&type=script&lang=js"
export * from "./DownloadV5.vue?vue&type=script&lang=js"
import style0 from "./DownloadV5.vue?vue&type=style&index=0&id=5752f9da&prod&lang=less&scoped=true"


/* normalize component */
import normalizer from "!../../node_modules/.store/vue-loader@15.11.1/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5752f9da",
  null
  
)

export default component.exports